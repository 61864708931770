import moment from 'moment'
import Vue from 'vue'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import VueCookies from 'vue-cookies'
import VueMoment from 'vue-moment'
import App from './App.vue'
import i18n from './language/i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
require('./api/prototype')

// Loading css files
import '@/assets/css/base.css'
import '@/assets/css/reset.css'
import '@/assets/css/themify-icons.css'
import '@/assets/css/utility.css'
import '@/assets/slick/slick-theme.css'
import '@/assets/slick/slick.css'

Vue.use(VueMoment, {moment})
Vue.use(VueCookies)
Vue.use(vueAwesomeCountdown)
VueCookies.config('1d')

Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  vuetify,
  VueCookies,
  render : h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import EN from './en'
import JA from './ja'

Vue.use(VueI18n)
const messages = {
  en : Object.assign({}, JA, EN),
  ja : Object.assign({}, EN, JA)
}

const language = navigator.language || navigator.userLanguage
const country = language ? language.substr(0, 2) : ''

export default new VueI18n({
  locale : 'ja', // Object.keys(messages).includes(country) ? country : 'en',
  messages
})

<template>
<footer class="footer">
  <div class="content_wrap">
    <div class="footer_wrap">
      <img @click="goto($define.PAGE_NAMES.TOP_PAGE_NAME)" class="f_logo" src="@/assets/images/common/logo01.png" />
    </div>
    <div class="d-flex">
      <div class="u-fs14">
        <p class="u-fw700 u-em12">サタデーオークション事務局　<span class="d-inline-block">株式会社エクモ</span></p>
        <p class="">お問い合わせ</p>
        <p class="tel u-em9">TEL : 077-516-4398</p>
        <p class="fax u-em9">FAX : 077-546-5162</p>
        <p class="email u-em9">E-mail : sat-auction@eqmo.co.jp</p>
      </div>
      <div class="company">
        <p class="adress">〒520-2279 滋賀県大津市黒津三丁目8-21</p>
        <p class="footer-link">
          <a @click="$routerGo($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{$t('LINK_PROFILE')}}</a> ／ <a @click="$routerGo($define.PAGE_NAMES.PRIVACY_PAGE_NAME)">{{$t('LINK_PRIVACY')}}</a> ／ <a @click="$routerGo($define.PAGE_NAMES.LOW_COMMERCE_PAGE_NAME)">特定商取引法に基づく表記</a>
        </p>
      </div>
    </div>
  </div>
  <div class="copyright">
    Copyright © Eqmo Corporation.
  </div>
</footer>
</template>
<script>
export default {
  props : {
    logined : {
      type    : Boolean,
      default : false
    }
  },
  data() {
    return {
    }
  },
  methods : {
    goto(dest, id = null, query = {}, hash = null) {
      this.$routerGo(dest, id, query, hash)
      window.scrollTo({
        top      : 0,
        left     : 0,
        behavior : 'smooth'
      })
    }
  }
}
</script>
<style scoped>
  @media screen and (min-width: 769px) {
    .v-application p {
      margin-bottom: inherit !important;
    }
  }
  @media screen and (max-width: 768px) {
    .v-application p {
      margin-bottom: initial !important;
    }
    p.adress {
      margin-bottom: 20px !important;
    }
  }
  .footer_wrap p {
    margin-bottom: initial !important;
  }
  .footer_wrap img {
    cursor: pointer;
  }
  .footer-link a {
    color: #333 !important;
    cursor: pointer !important;
    text-decoration: none !important;
  }
  .footer-link a:hover {
    color: #005BAC !important;
  }
</style>

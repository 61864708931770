import Vue from 'vue'
import Cookie from 'vue-cookies'
import VueGtag from 'vue-gtag'
import Router from 'vue-router'
import Define from './api/define.js'

const routes = [
  {
    path      : `/${Define.PAGE_NAMES.LOGIN_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.LOGIN_PAGE_NAME,
    component : () => import('./views/Login.vue'),
    meta      : {
      title     : Define.PAGE_TITLES.LOGIN_PAGE_NAME,
      bodyClass : 'login'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.TOP_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.TOP_PAGE_NAME,
    component : () => import('./views/Top.vue'),
    meta      : {
      title         : Define.PAGE_TITLES.TOP_PAGE_NAME,
      bodyClass     : 'home',
      enableQuery   : true,
      webSocketOn   : true,
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.CONTACT_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.CONTACT_PAGE_NAME,
    component : () => import('./views/Contact/Contact.vue'),
    meta      : {
      title         : Define.PAGE_TITLES.CONTACT_PAGE_NAME,
      bodyClass     : 'entry',
      headerNameTag : 'CONTACT_PAGE_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.CONTACT_PAGE_NAME}/item/:id`,
    name      : Define.PAGE_NAMES.ITEM_CONTACT_PAGE_NAME,
    component : () => import('./views/Contact/ItemContact.vue'),
    meta      : {
      title         : Define.PAGE_TITLES.ITEM_CONTACT_PAGE_NAME,
      bodyClass     : 'entry',
      headerNameTag : 'ITEM_CONTACT_PAGE_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.CONTACT_PAGE_NAME}/stock/:id`,
    name      : Define.PAGE_NAMES.STOCK_CONTACT_PAGE_NAME,
    component : () => import('./views/Contact/StockContact.vue'),
    meta      : {
      title         : Define.PAGE_TITLES.STOCK_CONTACT_PAGE_NAME,
      bodyClass     : 'entry',
      headerNameTag : 'ITEM_CONTACT_PAGE_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME,
    component : () => import('./views/RegisterMember.vue'),
    meta      : {
      title         : Define.PAGE_TITLES.NEW_MEMBER_REGISTRATION_PAGE_NAME,
      bodyClass     : 'entry',
      headerNameTag : 'PAGE_NEW_MEMBER_REGISTRATION'
    }
  },
  {
    path        : `/${Define.PAGE_NAMES.MY_PAGE_PAGE_NAME}`,
    name        : Define.PAGE_NAMES.MY_PAGE_PAGE_NAME,
    redirect : {
      name : Define.PAGE_NAMES.CHANGE_INFO_PAGE_NAME
    },
    component   : () => import('./views/MyPage.vue'),
    children    : [
      {
        path      : Define.PAGE_NAMES.CHANGE_INFO_PAGE_NAME,
        name      : Define.PAGE_NAMES.CHANGE_INFO_PAGE_NAME,
        component : () => import('./views/MyPage/ChangeInfo.vue'),
        meta        : {
          title        : Define.PAGE_TITLES.CHANGE_INFO_PAGE_NAME,
          requiresAuth : true
        }
      },
      {
        path      : Define.PAGE_NAMES.BID_HISTORY_PAGE_NAME,
        name      : Define.PAGE_NAMES.BID_HISTORY_PAGE_NAME,
        component : () => import('./views/MyPage/BidHistory.vue'),
        meta        : {
          title        : Define.PAGE_TITLES.BID_HISTORY_PAGE_NAME,
          requiresAuth : true
        }
      },
      {
        path      : Define.PAGE_NAMES.WITHDRAW_SUCCESS_PAGE_NAME,
        name      : Define.PAGE_NAMES.WITHDRAW_SUCCESS_PAGE_NAME,
        component : () => import('./views/MyPage/WithdrawSuccess.vue'),
        meta        : {
          title        : Define.PAGE_TITLES.WITHDRAW_SUCCESS_PAGE_NAME
        }
      }
    ],
    meta        : {
      title        : Define.PAGE_TITLES.MY_PAGE_PAGE_NAME
    }
  },

  {
    path      : `/${Define.PAGE_NAMES.REISSUE_PASSWORD_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.REISSUE_PASSWORD_PAGE_NAME,
    component : () => import('./views/ReissuePassword.vue'),
    meta      : {
      title         : Define.PAGE_TITLES.REISSUE_PASSWORD_PAGE_NAME,
      headerNameTag : 'LINK_FORGOT_PASSWORD_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME,
    component : () => import('./views/Auction/List.vue'),
    meta      : {
      title          : Define.PAGE_TITLES.AUCTION_LIST_PAGE_NAME,
      bodyClass      : 'item_p-list',
      enableQuery    : true,
      headerBidding  : true,
      headerFavorite : true,
      webSocketOn    : true,
      headerNameTag  : 'AUCTION_PAGE_HEADER_LABEL'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME}/:id`,
    name      : Define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME,
    component : () => import('./views/Auction/Detail.vue'),
    meta      : {
      title          : Define.PAGE_TITLES.AUCTION_DETAIL_PAGE_NAME,
      bodyClass      : 'item_p-detail',
      enableQuery    : false,
      headerBidding  : true,
      headerFavorite : true,
      webSocketOn    : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.STOCK_LIST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.STOCK_LIST_PAGE_NAME,
    component : () => import('./views/Stock/List.vue'),
    meta      : {
      title          : Define.PAGE_TITLES.STOCK_LIST_PAGE_NAME,
      bodyClass      : 'item_p-list',
      enableQuery    : true,
      headerFavorite : true,
      headerNameTag  : 'STOCK_PAGE_HEADER_LABEL'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.STOCK_LIST_PAGE_NAME}/:id`,
    name      : Define.PAGE_NAMES.STOCK_DETAIL_PAGE_NAME,
    component : () => import('./views/Stock/Detail.vue'),
    meta      : {
      title          : Define.PAGE_TITLES.STOCK_DETAIL_PAGE_NAME,
      bodyClass      : 'item_p-detail',
      enableQuery    : true,
      headerFavorite : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.TENDER_LIST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.TENDER_LIST_PAGE_NAME,
    component : () => import('./views/Tender/List.vue'),
    meta      : {
      title          : Define.PAGE_TITLES.TENDER_LIST_PAGE_NAME,
      bodyClass      : 'item_p-list',
      enableQuery    : true,
      headerBidding  : true,
      headerFavorite : true,
      headerNameTag  : 'TENDER_PAGE_HEADER_LABEL'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.TENDER_LIST_PAGE_NAME}/:id`,
    name      : Define.PAGE_NAMES.TENDER_DETAIL_PAGE_NAME,
    component : () => import('./views/Tender/Detail.vue'),
    meta      : {
      title          : Define.PAGE_TITLES.TENDER_DETAIL_PAGE_NAME,
      bodyClass      : 'item_p-detail',
      enableQuery    : true,
      headerBidding  : true,
      headerFavorite : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.GUILD_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.GUILD_PAGE_NAME,
    component : () => import('./views/Guild.vue'),
    meta      : {
      title         : Define.PAGE_TITLES.GUILD_PAGE_NAME,
      headerNameTag : 'PAGE_BEGINNER_GUIDE'
    }
  },

  {
    path      : `/${Define.PAGE_NAMES.INTRO_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.INTRO_PAGE_NAME,
    component : () => import('./views/Others/Intro.vue'),
    meta      : {
      title         : Define.PAGE_TITLES.INTRO_PAGE_NAME,
      headerNameTag : 'PAGE_BEGINNER_GUIDE'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHERS_PAGE_NAME}/${Define.PAGE_NAMES.PRIVACY_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.PRIVACY_PAGE_NAME,
    component : () => import('./views/Others/Privacy.vue'),
    meta      : {
      title         : Define.PAGE_TITLES.PRIVACY_PAGE_NAME,
      bodyClass     : 'sub',
      headerNameTag : 'LINK_PRIVACY'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHERS_PAGE_NAME}/${Define.PAGE_NAMES.PROFILE_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.PROFILE_PAGE_NAME,
    component : () => import('./views/Others/Profile.vue'),
    meta      : {
      title         : Define.PAGE_TITLES.PROFILE_PAGE_NAME,
      bodyClass     : 'sub',
      headerNameTag : 'LINK_PROFILE'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHERS_PAGE_NAME}/${Define.PAGE_NAMES.LOW_COMMERCE_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.LOW_COMMERCE_PAGE_NAME,
    component : () => import('./views/Others/LowCommerce.vue'),
    meta      : {
      title         : Define.PAGE_TITLES.LOW_COMMERCE_PAGE_NAME,
      bodyClass     : 'sub',
      headerNameTag : 'LINK_LOW_COMMERCE'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.FAQ_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.FAQ_PAGE_NAME,
    component : () => import('./views/Others/Faq.vue'),
    meta      : {
      title         : Define.PAGE_TITLES.FAQ_PAGE_NAME,
      bodyClass     : 'sub',
      headerNameTag : 'LINK_GUIDANCE_FAQ'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.PRINTER}`,
    name      : Define.PAGE_NAMES.PRINTER,
    component : () => import('./views/Print/ImagePrinter.vue'),
    meta        : {
      title        : Define.PAGE_TITLES.PRINTER
    }
  },
  {
    path      : '/system-error',
    name      : Define.PAGE_NAMES.SYSTEM_ERROR_PAGE_NAME,
    component : () => import('./views/SystemError.vue'),
    meta        : {
      title        : Define.PAGE_TITLES.SYSTEM_ERROR_PAGE_NAME
    }
  },
  {
    path     : '*',
    name     : Define.PAGE_NAMES.NOT_EXIST_PAGE_NAME,
    redirect : {name : Define.PAGE_NAMES.TOP_PAGE_NAME}
  }
]

const router = new Router({
  // Mode: 'hash', // モック作成時はこっちで実行
  mode   : 'history',
  base   : process.env.BASE_URL,
  routes
})

Vue.use(Router)
Vue.use(VueGtag, {
  config : {id : process.env.VUE_APP_GA_ID}
}, router)

Vue.prototype.$define = Define
Vue.prototype.$routerGo = function(name1, id1 = null, query1 = {}, hash1 = null, nickname1 = null) {
  const obj = {
    name  : name1,
    query : query1
  }
  if (id1 || nickname1) {
    obj.params = {
      id       : id1,
      nickname : nickname1
    }
  }
  if (hash1) {
    obj.hash = `#${hash1}`
  }
  router.push(obj).catch(() => {})
}

Vue.prototype.$routerGoNewWindow = function(name, id = null, query = {}, hash = null, nickname = null) {
  const obj = {
    name,
    query
  }
  if (id || nickname) {
    obj.params = {
      id,
      nickname
    }
  }
  if (hash) {
    obj.hash = `#${hash}`
  }
  const routeData = this.$router.resolve(obj)
  window.open(routeData.href, '_blank')
}

router.beforeEach((to, from, next) => {
  const menu_content = $('.menu-content')
  if (menu_content && menu_content.length > 0 && menu_content[0].offsetTop === 0) {
    $('#header-btn').trigger('click')
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (Cookie.get(Define.LOCAL_STORE_LABEL.SESSION_TOKEN)) {
      // If (to.name !== from.name) {
      window.scrollTo({
        top      : 0,
        left     : 0,
      })
      // }
      return next()
    } else {
      // セッションIDがなければログイン画面へリダイレクトする
      alert('ログインしてください。')
      return next({name : Define.PAGE_NAMES.LOGIN_PAGE_NAME})
    }
  } else {
    // If (to.name !== from.name) {
    window.scrollTo({
      top      : 0,
      left     : 0,
    })
    // }
    return next()
  }
})

// Set title for each page
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    const title = to.meta.title
    /*
     * If (to.name === Define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME) {
     *   title = to.params.nickname ? (to.params.nickname + '｜' + title) : title
     * }
     */
    if (to.name !== Define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME) {
      if (title) {
        document.title = title
      }
    }
  })
})

export default router

<template>
  <v-app class="v-content-100vh">
    <div class="wrap">
      <Header :logined="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null"/>
      <router-view/>
      <Footer :logined="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null"/>
      <v-overlay :value="$store.state.loading" :z-index="1002">
        <v-container fill-height>
          <v-layout row justify-center align-center class="loading-icon">
            <v-progress-circular indeterminate :size="70" :width="7" color="green"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </div>
  </v-app>
</template>

<script>
import Methods from '@/api/methods'
import Footer from './components/Common/Footer'
import Header from './components/Common/Header'

export default {
  name       : 'App',
  components : {
    Footer,
    Header
  },
  data() {
    return {
      scripts : [],
      focused : null
    }
  },
  created() {
    this.$moment.locale(this.$i18n.locale)
    window.document.title = this.$t('SITE_TITLE')
    document.addEventListener('focusin', this.focusIn)
    document.addEventListener('focusout', this.focusOut)
    document.addEventListener('keydown', event => {
      if (event.code === 'Space') {
        if (this.focused && this.focused.className.includes('space_click_event')) {
          this.focused.click()
          event.preventDefault()
        }
      }
    })
  },
  mounted() {
    Methods.addExternalScripts(this.scripts, '')
    this.updateLoginStatus()
    document.body.classList.add('sub')
    document.body.id = 'top'
  },
  methods : {
    focusIn(event) {
      const el = event.target
      if (el) {
        el.className += ' focused'
        this.focused = el
      }
    },
    focusOut(event) {
      const el = event.target
      if (el.className) {
        el.className = el.className.replace('focused', '')
      }
    },
    updateLoginStatus() {
      if (this.$cookies.get(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)) {
        document.body.classList.remove('state-out')
        document.body.classList.add('state-in')
      } else {
        document.body.classList.remove('state-in')
        document.body.classList.add('state-out')
      }
    }
  },
  watch : {
    '$route.query.lang'(value) {
      this.$i18n.locale = this.$route.query.lang ? this.$route.query.lang : this.$i18n.locale
    },
    '$i18n.locale'(value) {
      this.$moment.locale(value)
      document.body.classList.remove('open')
      window.document.title = this.$t('SITE_TITLE')
    },
    $route() {
      this.updateLoginStatus()
      const classList = this.$router.options.routes.map(route => route.meta ? route.meta.bodyClass : null).filter(bodyClass => bodyClass)
      for (const row of classList) {
        document.body.classList.remove(row)
      }
      if (this.$route.meta.bodyClass) {
        document.body.classList.add(this.$route.meta.bodyClass)
      }
    }
  }
}
</script>
<style lang="css">
  .v-application {
    color: inherit !important;
    font-family: inherit !important;
    line-height: inherit !important;
  }
  .theme--light.v-application {
    background: inherit !important;
    color: inherit !important;
  }
  .loading-dialog {
    background-color: #303030;
    opacity: .3;
  }
  .wrap .align-center {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
  }
  .wrap .justify-center {
      -webkit-box-pack: center!important;
      -ms-flex-pack: center!important;
      justify-content: center!important;
  }
  .wrap .green--text {
    color: #4caf50!important;
    caret-color: #4caf50!important;
  }
  .v-card__title {
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    letter-spacing: inherit !important;
  }
  .v-card__subtitle, .v-card__text {
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    letter-spacing: inherit !important;
  }
  .v-btn.v-size--default {
    font-size: inherit !important;
  }
</style>
<style lang="css">
  .horse_lineup_table td.td_link a {
    color: #fff !important;
  }
</style>
<style lang="css">
  /* @media screen and (min-width: 769px) {
    .row {
      margin: 0 -15px !important;
    }
  } */
  .col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,
  .col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-auto{
    padding: 0 15px !important;
  }
  .horse_li_data p {
    margin-right: 1em !important;
  }
</style>
<style lang="css">
  .header_login_link, .header_list_link a, .menu-content a, .login-form a, .form-privacy a, .btn_register {
    cursor: pointer !important;
    text-decoration: none !important;
  }
  .horse_lineup_table td.td_sex[data-sex="gelding"]:before{
    content: 'セン';
    color: #01c701;
  }
  .horse_lineup_table td.td_sex[data-sex="male"]:before{
    color: #000000 !important;
  }
  .txt-err {
    color: #f00;
  }
</style>

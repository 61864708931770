import moment from 'moment'
import util from 'util'
import Cookies from 'vue-cookies'
import zlib from 'zlib'
import Define from './define'
import Api from './index'

export default {
  // ロケーションプロトコルがhttpsかを返す
  boolHttpsProtocol() {
    return location.protocol === 'https:'
  },
  // APIサーバーに実行用パラメーターをもたせたのPOSTを送る
  apiExcute(path, languageCode = 'en', params = {}) {
    return Promise.resolve()
      .then(() => {
        const config = {
          headers : {
            authorization : Cookies.get(Define.LOCAL_STORE_LABEL.SESSION_TOKEN) || 'public'
          }
        }
        return Api().post(path, Object.assign({languageCode : languageCode || 'en'}, params), config)
      })
      .then(result => {
        const unzip = util.promisify(zlib.unzip)
        return unzip(Buffer.from(result.data.data))
      })
      .then(data => {
        console.log(`API:${path}, レスポンス:`, JSON.parse(data.toString()))
        return Promise.resolve(JSON.parse(data.toString()))
      })
      .catch(error => {
        console.log(error)
        return Promise.reject(error)
      })
  },
  parseHtmlResponseError(router, error) {
    console.log('error', error)
    if (error.response && (error.response.status === 400 || error.response.status === 409)) {
      // Sqlでのエラーを画面に表示するための処理
      if (error.response.data.errors) {
        return error.response.data.errors
      } else {
        return error.response.data
      }
    } else if (error.response && error.response.status === 401) {
      Cookies.remove(Define.LOCAL_STORE_LABEL.SESSION_TOKEN)
      if (router.currentRoute.name !== 'login') {
        router.push('/')
      }
      return error.response.data
    } else if (error.response && error.response.status === 403) {
      Cookies.remove(Define.LOCAL_STORE_LABEL.SESSION_TOKEN)
      router.push('/')
      return null
    } else {
      Cookies.remove(Define.LOCAL_STORE_LABEL.SESSION_TOKEN)
      router.push('/system-error')
      return null
    }
  },
  addExternalScripts(scripts, ROOT = process.env.VUE_APP_DESIGN_JAVASCRIPT_PATH, async = false) {
    scripts.forEach(script => {
      const tag = document.createElement('script')
      if (async) {
        tag.setAttribute('async', 'async')
      }
      tag.setAttribute('src', ROOT + script)
      document.head.appendChild(tag)
    })
  },
  // Timestamp with time zoneをYYYY-MM-DDの形で返す
  getFormatDate(date, format = 'YYYY-MM-DD') {
    return date ? moment(date).format(format) : ''
  },

  // Timestamp with time zoneをHH-mmの形で返す
  getFormatTime(date, format = 'HH:mm') {
    return date ? moment(date).format(format) : ''
  },
  // Timestamp with time zoneをYYYY/MM/DD HH:mmの形で返す
  getFormatDateTime(date, format = 'YYYY/MM/DD HH:mm') {
    moment.locale('ja')
    return date ? moment(date).format(format) : ''
  },
}

/**
 * 英語
 */
export default {
  SITE_TITLE                              : 'Saturday Auction Official Site',
  NAME                                    : 'English',
  LANGUAGE_CODE                           : 'EN',
  SIDE_NAME                               : 'Thoroughbred Auction Official Site',
  SIDE_LOGO_TITLE                         : 'EQMO',
  PAGE_TOP                                : 'TOP',

  HEADER_BIDDING_BUTTON_LABEL             : 'Bidding',
  HEADER_FAVORITE_LIST_BUTTON_LABEL       : 'Wish List',
  HEADER_LOGOUT_BUTTON_LABEL              : 'Sign out',
  PAGE_MEMBER_REGISTRATION                : 'Members',
  PAGE_MEMBER_REGISTRATION_NAME           : 'Member registration application',
  PAGE_LOGIN                              : 'Sign in',
  PAGE_NEW_MEMBER_REGISTRATION            : 'New member registration',
  PAGE_MY_PAGE                            : 'MyPage',
  PAGE_BEGINNER_GUIDE                     : 'Guides',
  LINK_GUIDANCE_FAQ                       : 'FAQ',
  LINK_PROFILE                            : 'About us',
  LINK_PRIVACY                            : 'Privacy Policy',

  LABEL_LOGINID                                 : 'User ID',
  PLACEHOLDER_USERID                            : '',
  LABEL_PASSWORD                                : 'Password',
  PLACEHOLDER_PASSWORD                          : '',
  LABEL_USERNAME                                : 'User Name',
  PLACEHOLDER_USERNAME                          : '',
  PS_USERNAME_BEFORE                            : 'Please enter the name of the person in charge of logging in.',
  PS_USERNAME_AFTER                             : 'Can check the history by person in charge, after logging in.',
  LABEL_SAVE_ID_PASSWORD_USERNAME               : 'Save User ID / Password / User Name',
  LINK_FORGOT_PASSWORD                          : 'Forgot password?',
  LINK_FORGOT_PASSWORD_NAME                     : 'Forgot password',
  LABEL_PARTICIPATION_TERMS                     : 'Terms of Service',
  LABEL_AGREE_TERMS_PARTICIPATION               : 'I agree to the Terms of Service',
  LINK_NEW_MEMBER_REGISTRATION_APPLICATION_FREE : 'New member registration(free)',
  PS_MEMBERSHIP_REGISTRATION_REQUIRED           : '*Member account is required to see the price of construction equipment.',
  OTHER_LANGUAGE                                : '日本語',
  OTHER_LANGUAGE_SHOT                           : 'JA',
  OTHER_LANGUAGE_CODE                           : 'ja',
  DATE_FORMAT                                   : 'dd, DD/MM/YYYY',
  DATE_TIME_FORMAT                              : 'dd, DD/MM/YYYY HH:mm',
  ROW_BID_DATE_TIME_FORMAT                      : 'DD/MM HH:mm',

  TEXT_FORMAT       : '^([^\x01-\x7E]|[0-9a-zA-Z -/:-@\[-~])+$',
  TEXT_FORMAT_ERROR : 'Please enter in half-width alphanumerical characters, full-width characters, and symbols.',

  CURRENT_PASSWORD_ERROR_MESSAGE : 'When changing the password, enter the current password.',
  PASSWORD_CONFIRM_ERROR_MESSAGE : 'The password confirmation does not match.',
  MAIL_CONFIRM_ERROR_MESSAGE     : 'The email confirmation does not match.',

  NO_RECOMMEND_ITEM_IN_SESSION_MESSAGE  : 'Current {names} bid opening ',
  NO_RECOMMEND_ITEM_SCHEDULED_MESSAGE   : 'the next upcoming auction will take place <br> {start_day}~<br>Please look forward to it.',
  NO_RECOMMEND_ITEM_NO_SCHEDULE_MESSAGE : 'Please look forward to the next upcoming auction.',

  TOP_PAGE_AUCTION_RECOMMEND_ITEMS_VIEW_HEADER            : 'WEB auction / Tender machine',
  TOP_PAGE_AUCTION_NEW_ITEMS_VIEW_HEADER                  : 'Stock machine new arrival',
  TOP_PAGE_LIST_OF_AUCTION_EXHIBITORS_BUTTON_PREFIX_LABEL : 'Auction',
  TOP_PAGE_LIST_OF_AUCTION_EXHIBITORS_BUTTON_SUBFIX_LABEL : 'Machine List',
  TOP_PAGE_LIST_OF_TENDER_EXHIBITORS_BUTTON_PREFIX_LABEL  : 'Tender',
  TOP_PAGE_LIST_OF_TENDER_EXHIBITORS_BUTTON_SUBFIX_LABEL  : 'Machine List',
  TOP_PAGE_CRANE_LIST_BUTTON_PREFIX_LABEL                 : 'Crane',
  TOP_PAGE_CRANE_LIST_BUTTON_SUBFIX_LABEL                 : 'List',
  TOP_PAGE_AUCTION_NEW_ITEMS_BUTTON_LABEL                 : 'List',
  TOP_PAGE_INVENTORY_MACHINE_LIST_BUTTON_LABEL            : 'Stock Machine List',
  TOP_PAGE_LIST_OF_CRANE_STOCK_MACHINES_BUTTON_LABEL      : 'Cranes Stock List',
  TOP_PAGE_AUCTION_SCHEDULE_VIEW_HEADER                   : 'Bid Schedule',
  TOP_PAGE_AUCTION_STATUS_AUCTION_IN_SESSION_LABEL        : 'Opening',
  TOP_PAGE_AUCTION_STATUS_AUCTION_WILL_BE_HELD_LABEL      : 'Upcoming',
  TOP_PAGE_AUCTION_STATUS_AUCTION_ENDED_LABEL             : 'Ended',
  TOP_PAGE_ITEM_MODEL_LABEL                               : 'Model',
  TOP_PAGE_ITEM_MODEL_SERIAL_NUMBER_LABEL                 : 'Unit',
  TOP_PAGE_ITEM_MODEL_YEAR_LABEL                          : 'Years',
  TOP_PAGE_NOTICES_VIEW_HEADER                            : 'Notice',
  TOP_PAGE_FOR_NEWBIE_LABEL                               : 'Guides',
  TOP_PAGE_AUCTION_GUIDE_LINK_LABEL                       : 'Flow of WEB auction / Bidding',
  TOP_PAGE_PRICE_CURRENCY_MARK                            : 'JPY ',

  MEMBER_REQUEST_DOCUMENT_EXPLANATION_MESSAGE          : 'We are reviewing the application for membership registration.</br>Please download from the following and prepare the necessary documents</br>in PDF (JPEG / PNG is also possible).',
  MEMBER_REQUEST_DOCUMENT_CAUTION_MESSAGE              : "* When registering as a member in the name of an individual, description in the notes of individual name instead of a company name for 2, and please submit a copy of your personal identification card such as a driver's license for 3.",
  MEMBER_REQUEST_DOCUMENT_TITLE                        : 'Documents required for membership application',
  MEMBER_REQUEST_DOWNLOAD_SAMPLE_DOCUMENT_BUTTON_LABEL : 'Download application required documents',
  MEMBER_REQUEST_FORM_CAUTION_TITLE                    : '[Caution] To construction machinery users in Japan',
  MEMBER_REQUEST_FORM_CAUTION_MESSAGE                  : '<span class = "fontB">[Caution] To construction machinery users in Japan</span><br> Kobelco Construction Machinery International Trading Co., Ltd. does not sell directly construction machinery to users in Japan.<br> Anyone who wishes to purchase, please contact from <a href="https://www.kobelco-kenki.co.jp/" target="_blank">Sales Service (Go to the Kobelco Construction Machinery website ). </a>',
  MEMBER_REQUEST_FORM_ENTRY_FORM_INFO_MESSAGE          : 'Once you have the required documents, please fill out the form below. Please be sure to enter the items marked with<br><em class = "req"> required</em>.',
  MEMBER_REQUEST_FORM_COUNTRY_LABEL                    : 'Country<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_COMPANY_NAME_LABEL               : 'Company name / Organization name<em class = "req">required</ em>',
  MEMBER_REQUEST_FORM_REPRESENTATIVE_DIRECTOR_LABEL    : 'Representative name',
  MEMBER_REQUEST_FORM_BRANCH_OFFICE_LABEL              : 'Branch office ・ sales office name',
  MEMBER_REQUEST_FORM_USER_NAME_LABEL                  : 'Name of person in charge<em class = "req"> required</em>',
  MEMBER_REQUEST_FORM_POST_CODE_LABEL                  : 'Zip code',
  MEMBER_REQUEST_FORM_ADDRESS_LABEL                    : 'Address<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_REQUIRED_LABEL                   : 'required',
  MEMBER_REQUEST_FORM_PREFECTURES_LABEL                : 'Prefectures',
  MEMBER_REQUEST_FORM_MUNICIPALITIES_LABEL             : 'Municipalities',
  MEMBER_REQUEST_FORM_LOCATION_LABEL                   : 'Location',
  MEMBER_REQUEST_FORM_LOCATION_LABEL_EN                : 'Address',
  MEMBER_REQUEST_FORM_TEL_LABEL                        : 'Phone number<em class = "req"> required </ em>',
  MEMBER_REQUEST_FORM_FAX_LABEL                        : 'Fax number',
  MEMBER_REQUEST_FORM_EMAIL_LABEL                      : 'Email<em class = "req"> required </ em>',
  MEMBER_REQUEST_FORM_CONFIRM_EMAIL_LABEL              : 'Email<br class="only_pc">(Confirmation)<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_PASSWORD_LABEL                   : 'Password<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_CONFIRM_PASSWORD_LABEL           : 'Password(Confirmation) <em class = "req"> required</em>',
  MEMBER_REQUEST_FORM_PASSWORD_RULE                    : '<p class="kome">*Your password must be between 8 and 14 characters and contain at least 1 number.</p>',
  MEMBER_REQUEST_FORM_ANTIQUE_FILE_LABEL               : 'A copy of<br class="only_pc">secondhand dealer permit<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_EXPORT_FILE_LABEL                : 'Related to<br class="only_pc">Signed Export Control<br class="only_sp">Notes<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_NAME_CARD_FILE_LABEL             : 'Business card copy<br class="only_pc">of the Representative<em class = "req">required</ em>',
  MEMBER_REQUEST_FORM_AGREE_LABEL                      : 'Handling of<br class="only_pc">personal information<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL             : 'I Agree',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE1   : 'Please be sure to read',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2   : 'Privacy Policy',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE3   : 'before submitting.',
  MEMBER_REQUEST_FORM_FILE_SELECT_BUTTON_LABEL         : 'Select files',
  MEMBER_REQUEST_FORM_FILE_DELETE_BUTTON_LABEL         : 'Delete',
  MEMBER_REQUEST_FORM_FILE_UNSELECTED_MESSAGE          : 'File is not selected.',
  MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_MESSAGE      : '<p class="kome">*PDF / JPEG / PNG:5MB or less</p><p class="kome">*Please set the file name in half-width alphanumerical characters before attaching.</p>',
  MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_NONJP        : '<p class="kome">*Please upload English version.</p>',
  MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_HEADER       : 'Documents required for membership application',
  MEMBER_REQUEST_FORM_SUBMIT_LABEL                     : 'Confirm',
  MEMBER_REQUEST_FORM_SEARCH_POST_CODE_BUTTON_LABEL    : 'Search',
  MEMBER_REQUEST_FORM_FULL_WIDTH_LABEL                 : '[Full-width]',
  MEMBER_REQUEST_FORM_FULL_WIDTH_NUMBERS_LABEL         : '[Half-width numbers]',
  MEMBER_REQUEST_FORM_FULL_WIDTH_ALPHANUMERICAL_LABEL  : '[Half-width alphanumerical]',
  MEMBER_REQUEST_FORM_POST_CODE_PLACEHOLDER            : '',
  MEMBER_REQUEST_FORM_ADDRESS_PLACEHOLDER              : '',
  MEMBER_REQUEST_FORM_PREFECTURES_PLACEHOLDER          : '',
  MEMBER_REQUEST_FORM_MUNICIPALITIES_PLACEHOLDER       : '',
  MEMBER_REQUEST_FORM_LOCATION_PLACEHOLDER             : '',
  MEMBER_REQUEST_FORM_PHONE_NUMBER_PLACEHOLDER         : '',
  MEMBER_REQUEST_FORM_FAX_NUMBER_PLACEHOLDER           : '',
  MEMBER_REQUEST_FORM_WITHDRAW_LABEL                   : 'Withdraw',
  MEMBER_REQUEST_TEXT_FORMAT_ERROR                     : 'Please enter in half-width alphanumericals / symbols.',
  MEMBER_EDIT_FORM_FILE_CHANGING_LABEL                 : '*Please contact us for changing attachment file',
  MEMBER_EDIT_FORM_EXPORT_FILE_LABEL                   : 'A copy of<br class="only_pc">secondhand dealer permit',
  MEMBER_EDIT_FORM_ANTIQUE_FILE_LABEL                  : 'Related to<br class="only_pc">Signed Export Control<br class="only_sp">Notes',
  MEMBER_EDIT_FORM_NAME_CARD_FILE_LABEL                : 'Business card copy<br class="only_pc">of the Representative',

  MEMBER_REQUEST_CONFIRM_EXPLANATION_MESSAGE           : 'Please confirm and if there are no mistakes, click 「Send」.',
  MEMBER_REQUEST_CONFIRM_COUNTRY_LABEL                 : 'Country',
  MEMBER_REQUEST_CONFIRM_COMPANY_NAME_LABEL            : 'Company name ・ Organization name',
  MEMBER_REQUEST_CONFIRM_REPRESENTATIVE_DIRECTOR_LABEL : 'Representative name',
  MEMBER_REQUEST_CONFIRM_BRANCH_OFFICE_LABEL           : 'Branch office ・ sales office name',
  MEMBER_REQUEST_CONFIRM_USER_NAME_LABEL               : 'Name of person in charge',
  MEMBER_REQUEST_CONFIRM_POST_CODE_LABEL               : 'Zip code',
  MEMBER_REQUEST_CONFIRM_ADDRESS_LABEL                 : 'Address',
  MEMBER_REQUEST_CONFIRM_PREFECTURES_LABEL             : 'Prefectures',
  MEMBER_REQUEST_CONFIRM_MUNICIPALITIES_LABEL          : 'Municipalities',
  MEMBER_REQUEST_CONFIRM_LOCATION_LABEL                : 'Location',
  MEMBER_REQUEST_CONFIRM_FAX_LABEL                     : 'Fax number',
  MEMBER_REQUEST_CONFIRM_EMAIL_LABEL                   : 'Email',
  MEMBER_REQUEST_CONFIRM_CONFIRM_EMAIL_LABEL           : 'Email<br class="only_pc">(Confirmation)',
  MEMBER_REQUEST_CONFIRM_PASSWORD_LABEL                : 'Password',
  MEMBER_REQUEST_CONFIRM_CONFIRM_PASSWORD_LABEL        : 'Password(Confirmation)',
  MEMBER_REQUEST_CURRENT_PASSWORD_LABEL                : 'Password(Current)',
  MEMBER_REQUEST_CONFIRM_EXPORT_FILE_LABEL             : 'A copy of<br class="only_pc">secondhand dealer permit',
  MEMBER_REQUEST_CONFIRM_ANTIQUE_FILE_LABEL            : 'Related to<br class="only_pc">Signed Export Control<br class="only_sp">Notes',
  MEMBER_REQUEST_CONFIRM_NAME_CARD_FILE_LABEL          : 'Business card copy<br class="only_pc">of the person in charge',
  MEMBER_REQUEST_CONFIRM_AGREE_LABEL                   : 'Handling of<br class="only_pc">personal information',
  MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL             : 'Back',
  MEMBER_REQUEST_CONFIRM_SEND_BUTTON_LABEL             : 'Send',

  MEMBER_REQUEST_COMPLETION_MESSAGE                  : 'The membership application has been completed.',
  MEMBER_REQUEST_COMPLETION_GO_TO_LOGIN_BUTTON_LABEL : 'Back to Sign in page',

  REISSUE_PASSWORD_EXPLANATION_MESSAGE                 : 'If you have forgotten your password, please enter your registered email. <br>When you press the [Send] button, the password will be sent to the registered email.',
  REISSUE_PASSWORD_CAUTION_MESSAGE                     : 'If you have forgotten your email address, please contact us.',
  REISSUE_PASSWORD_FORM_ID_LABEL                       : 'ID',
  REISSUE_PASSWORD_FORM_EMAIL_LABEL                    : 'Email',
  REISSUE_PASSWORD_FORM_CONFIRM_EMAIL_LABEL            : 'Email(Confirmation)',
  REISSUE_PASSWORD_FORM_ID_PLACEHOLDER                 : 'Half-width alphanumerical',
  REISSUE_PASSWORD_FORM_EMAIL_PLACEHOLDER              : 'Half-width alphanumerical',
  REISSUE_PASSWORD_FORM_SUBMIT_LABEL                   : 'Send',
  REISSUE_PASSWORD_COMPLETION_MESSAGE                  : 'We have sent a new password to your email.',
  REISSUE_PASSWORD_COMPLETION_GO_TO_LOGIN_BUTTON_LABEL : 'Back to Sign in page',

  AUCTION_PAGE_SEARCH_HEADER : 'Auction machine search conditions',
  AUCTION_PAGE_HEADER_LABEL  : 'Auction machine',
  TENDER_PAGE_SEARCH_HEADER  : 'Tender machine search conditions',
  TENDER_PAGE_HEADER_LABEL   : 'Tender machine',
  STOCK_PAGE_SEARCH_HEADER   : 'Stock machine search conditions',
  STOCK_PAGE_HEADER_LABEL    : 'Stock machine',

  AUCTION_PAGE_SEARCH_TYPE : 'Auction',
  TENDER_PAGE_SEARCH_TYPE  : 'Tender',
  STOCK_PAGE_SEARCH_TYPE   : 'Stock machine',

  ITEM_SEARCH_KEYWORD_LABEL                          : 'Keyword',
  ITEM_SEARCH_KEYWORD_PLACEHOLDER                    : '例)nickname',
  ITEM_SEARCH_INVENTORY_AREA_LABEL                   : 'Stock place',
  ITEM_SEARCH_CATEGORY_LABEL                         : 'Category',
  ITEM_SEARCH_MODEL_YEAR_LABEL                       : 'Years',
  ITEM_SEARCH_NAME_LABEL                             : 'Nickname',
  ITEM_SEARCH_NAME_PLACEHOLDER                       : 'Ex)Key Season',
  ITEM_SEARCH_AGE_LABEL                              : 'Age',
  ITEM_SEARCH_GENDER_LABEL                           : 'Gender',
  ITEM_SEARCH_PRICE_LABEL                            : 'Price',
  ITEM_SEARCH_PRICE_UNIT                             : '10K',
  ITEM_SEARCH_FAVORITE_LABEL                         : 'Favorite',
  ITEM_SEARCH_BIDDING_LABEL                          : 'Bidding',
  ITEM_SEARCH_EXCEEDING_THE_LOWEST_BID_LABEL         : 'Exceeding the lowest bid',
  ITEM_SEARCH_SOLD_OUT_LABEL                         : 'Other than sold out',
  ITEM_SEARCH_RECOMMEND_LABEL                        : 'Recommend',
  ITEM_SEARCH_SEARCH_BUTTON_LABEL                    : 'Search',
  ITEM_SEARCH_CONDITIONS_LABEL                       : 'Conditions',
  ITEM_SEARCH_SEARCH_RESULTS_LABEL                   : 'Search results',
  ITEM_SEARCH_SEARCH_RESULTS_SUBJECT_LABEL           : '',
  ITEM_SEARCH_SEARCH_RESULTS_NUMBER_SUBJECT_LABEL    : ' Units',
  ITEM_SEARCH_CATEGORY_SEARCH_RESULTS_NUMBER_LABEL   : ' Units',
  ITEM_SEARCH_PRINT_ITEMS_CSV_BUTTON_LABEL           : 'Machine list CSV',
  ITEM_SEARCH_BULK_BIDDING_BUTTON_LABEL              : 'Place bids all at once',
  ITEM_SEARCH_CURRENT_PRICE_SORT_LABEL               : 'Current price',
  ITEM_SEARCH_RESULTS_LIMIT_LABEL                    : 'Results per page',
  ITEM_SEARCH_BULK_BIDDING_MODAL_HEADER              : 'Place bids all at once',
  ITEM_SEARCH_CONFIRM_BIDDING_MODAL_HEADER           : 'Bid confirmation',
  ITEM_SEARCH_BULK_BIDDING_MODAL_BID_BUTTON_LABEL    : 'Place a bid',
  ITEM_SEARCH_BULK_BIDDING_MODAL_CANCEL_BUTTON_LABEL : 'Close',

  ITEM_ROW_TOP_STATUS_LABEL                       : 'Highest bidder',
  ITEM_ROW_SECOND_STATUS_LABEL                    : 'Second Bidder',
  ITEM_ROW_EXCEEDING_THE_LOWEST_BID_STATUS_LABEL  : 'Exceeding the lowest bid',
  ITEM_ROW_MORE_LITTLE_LABEL                      : 'A little after',
  ITEM_ROW_CANCEL_LABEL                           : 'Stop listing',
  ITEM_ROW_AUCTION_STATUS_LABEL                   : 'Auction',
  ITEM_ROW_MAKER_LABEL                            : 'Maker',
  ITEM_ROW_YYYY_LABEL                             : 'Years',
  ITEM_ROW_YYYY_UNIT_LABEL                        : '',
  ITEM_ROW_YYYY_NOT_CLEAR_LABEL                   : '',
  ITEM_ROW_MDLGR_LABEL                            : 'Model',
  ITEM_ROW_OPETIM_LABEL                           : 'Hours',
  ITEM_ROW_OPETIM_NOT_CLEAR_LABEL                 : '',
  ITEM_ROW_ACTSERNO_LABEL                         : 'Unit',
  ITEM_ROW_CURRENT_PRICE_LABEL                    : 'Current price',
  ITEM_ROW_START_PRICE_LABEL                      : 'Lowest bid price',
  ITEM_ROW_PRICE_CURRENCY_MARK                    : 'JPY ',
  ITEM_ROW_START_STATUS_LABEL                     : 'Start',
  ITEM_ROW_END_STATUS_LABEL                       : 'End',
  ITEM_ROW_ENDED_STATUS_LABEL                     : 'Ended',
  ITEM_ROW_SELLING_PRICE_LABEL                    : 'Selling price',
  ITEM_ROW_SOLD_OUT_LABEL                         : 'SOLD OUT',
  ITEM_ROW_BID_PRICE_LABEL                        : 'Bid price',
  ITEM_ROW_BID_BUTTON_LABEL                       : 'Place a bid',
  ITEM_ROW_MEMBERS_ONLY_LABEL                     : 'Members only',
  ITEM_ROW_LOGIN_LINK_LABEL                       : 'Sign in',
  ITEM_ROW_NEW_MEMBER_REGISTRATION_BUTTON_LABEL   : 'New member registration(free)',
  ITEM_ROW_DETAIL_LINK_LABEL                      : 'Details',
  ITEM_ROW_BULK_BIDDING_MODAL_DELETE_BUTTON_LABEL : 'Delete',
  ITEM_ROW_BID_SUCCESS_MESSAGE                    : 'Your bid has been accepted.',
  ITEM_ROW_CANCEL_SUCCESS_MESSAGE                 : 'Your bid has been cancelled.',
  ITEM_ROW_BID_CONFIRM_MESSAGE                    : 'Make sure about your bidding price, and click the Place a bid Button.',
  ITEM_ROW_MULTIPLE_ITEMS_LABEL                   : ' units',

  AUCTION_DETAIL_PAGE_NAME : 'Auction detail',
  STOCK_DETAIL_PAGE_NAME   : 'Stock machine detail',
  TENDER_DETAIL_PAGE_NAME  : 'Tender detail',

  DETAIL_LOWEST_BID_ACCEPT_PRICE_LABEL           : 'Lowest bid price',
  DETAIL_BID_STATUS_LABEL                        : 'Bid status',
  DETAIL_BID_STATUS_UNBID_LABEL                  : 'Unbidden',
  DETAIL_BID_STATUS_BIDED_LABEL                  : 'Completed bid',
  DETAIL_BID_STATUS_MORE_LITTLE_LABEL            : 'A little after',
  DETAIL_BID_STATUS_SUCCESSFUL_BID_RIGHT_LABEL   : 'Has a winning bid right',
  DETAIL_BID_AUTOMATIC_BIDDING_LABEL             : 'Automatic bidding',
  DETAIL_DOWNLOAD_IMAGES_BUTTON_LABEL            : 'Download all images',
  DETAIL_CONTACT_PRODUCT_BUTTON_LABEL            : 'Contact us about this product',
  DETAIL_ACHIEVEMENT_REPORT_BUTTON_LABEL         : 'See the total achievement report',
  DETAIL_PRODUCT_INFORMATION_LABEL               : 'Product information',
  DETAIL_AWAMETER_LABEL                          : 'Hour meter',
  DETAIL_MILEAGE_LABEL                           : 'Mileage',
  DETAIL_STOCK_LOCATION_LABEL                    : 'Stock place',
  DETAIL_VEHICLE_INSPECTION_LABEL                : 'Vehicle inspection',
  DETAIL_MAXIMUM_RATED_TOTAL_LOAD_LABEL          : 'Maximum rated total load',
  DETAIL_CR_INSPECTION_LABEL                     : 'CR inspection',
  DETAIL_SPECIFICATION_LABEL                     : 'Specification',
  DETAIL_HOOK_LABEL                              : 'Hook',
  DETAIL_OUTRIGGER_LABEL                         : 'Outrigger',
  DETAIL_REMARKS_LABEL                           : 'Remarks',
  DETAIL_CONTACT_PRODUCT_BOTTOM_BUTTON_LABEL     : 'Contact us about product',
  DETAIL_CLOSE_BUTTON_LABEL                      : 'Close',
  DETAIL_NOTE_TEXT_TOP                           : 'As-is storage, as-is delivery, pre-sale priority, no guarantee',
  DETAIL_NOTE_TEXT_BOTTOM                        : 'Important: Please be careful about the storage area.',
  DETAIL_LIST_OF_STORAGE_LOCATIONS_BUTTON_LABEL  : 'List of storage area',
  DETAIL_ITEM_LOT_ITEMS_PS_TEXT                  : '* This lot contains multiple products.',
  DETAIL_ITEM_PREV_BUTTON_LABEL                  : 'prev',
  DETAIL_ITEM_NEXT_BUTTON_LABEL                  : 'next',
  DETAIL_ITEM_SNS_SHARE_LABEL                    : 'Share this item',
  DETAIL_ITEM_HOLDING_PERIOD_LABEL               : 'Opening period',
  DETAIL_ITEM_SNS_SHARE_TEXT                     : '',
  DETAIL_ITEM_BID_REMAINING_TIME                 : 'Time remaining',
  DETAIL_ITEM_BID_EXTENDED_END                   : 'Entended at',

  CONTACT_PAGE_NAME                  : 'Inquiry',
  ITEM_CONTACT_PAGE_NAME             : 'Inquiries about product',
  CONTACT_FORM_INFO_TEXT             : 'Please input the following items, be sure to input <em class = "req"> required</em> item.',
  CONTACT_FORM_CONTACT_CONTENT_LABEL : 'Content of Inquiry',
  CONTACT_EXPORT_FILE_LABEL          : 'Attachment',
  CONTACT_FORM_GUEST_TEXT            : 'The guests',
  CONTACT_FORM_UDRCARR_LABEL         : 'Undercarriage',
  CONTACT_FORM_CAB_LABEL             : "Driver's cab",
  CONTACT_FORM_CLASS_LABEL           : 'Class',
  CONTACT_FORM_AREA_LABEL            : 'Storage area',
  CONTACT_COMPLETION_MESSAGE         : 'The inquiry has been completed.',

  MY_PAGE_SUCCESSFUL_BID_HISTORY_SEARCH_CONDITION_VIEW_HEADER : 'Winning bids search condition',
  MY_PAGE_SEARCH_CONDITION_METHOD_LABEL                       : 'Method',
  MY_PAGE_SEARCH_CONDITION_BIDDING_DATE_LABEL                 : 'Ending date',
  MY_PAGE_SEARCH_CONDITION_BIDDING_FROM_DATE_PLACEHOLDER      : 'ex) 2021/01/01',
  MY_PAGE_SEARCH_CONDITION_BIDDING_TO_DATE_PLACEHOLDER        : 'ex) 2021/01/01',
  MY_PAGE_SEARCH_BUTTON_LABEL                                 : 'Search',
  MY_PAGE_SEARCH_CONDITION_LABEL                              : 'Conditions',
  MY_PAGE_SEARCH_RESULT_LABEL                                 : 'Search results',
  MY_PAGE_SEARCH_RESULT_COUNT_LABEL                           : '{count} results',
  MY_PAGE_SEARCH_RESULT_DISPLAY_COUNT_LABEL                   : 'Results per page',
  MY_PAGE_SEARCH_RESULT_SORT_DISPLAY_COUNT_LABEL              : '{display_count}',
  MY_PAGE_ITEM_BIDDING_END_DATETIME_LABEL                     : 'Ending datetime',
  MY_PAGE_COLON_LABEL                                         : ':',
  MY_PAGE_ITEM_MAKER_LABEL                                    : 'Maker',
  MY_PAGE_ITEM_MODEL_LABEL                                    : 'Model',
  MY_PAGE_ITEM_YEAR_LABEL                                     : 'Years',
  MY_PAGE_ITEM_OPERATING_TIME_LABEL                           : 'Awameter',
  MY_PAGE_ITEM_MODEL_SERIAL_NUMBER_LABEL                      : 'Unit',
  MY_PAGE_ITEM_CONTRACT_PRICE_LABEL                           : 'Contract price',
  MY_PAGE_ITEM_BID_PRICE_LABEL                                : 'Bid price',
  MY_PAGE_ITEM_CONTRACT_BIDDER_LABEL                          : 'Winning Bidder',
  MY_PAGE_ITEM_PRODUCT_IMAGE_DOWNLOAD_BUTTON_LABEL            : 'Product image download',
  MY_PAGE_SHOW_MORE_BUTTON_LABEL                              : 'See more',
  MY_PAGE_PRICE_CURRENCY_MARK                                 : 'JPY ',
  MY_PAGE_YEAR_MARK                                           : '',
  MY_PAGE_NO_MARK                                             : 'No.',
  MY_PAGE_SHOW_ITEMS_LINK_LABEL                               : 'Show lot details',
  MY_PAGE_HIDE_ITEMS_LINK_LABEL                               : 'Close lot details',

  MY_PAGE_HISTORY_HEADER_TEXT                                 : 'Successful bid history',
  MY_PAGE_HISTORY_HORSE_NAME_LABEL                            : 'Name',

  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_HEADER_TEXT      : 'Confirmation dialog',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_MESSAGE_TEXT     : 'May I withdraw from the membership?',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_OK_LABEL         : 'OK',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL     : 'Close',

  OTHERS_PROFILE_TEXT                           : 'Company Outline',
  OTHERS_PROFILE_COMPANY_NAME_LABEL             : 'Name',
  OTHERS_PROFILE_COMPANY_NAME_TEXT              : 'Kobelco Construction Machinery International Trading Co., Ltd.',
  OTHERS_PROFILE_COMPANY_NAME_OTHER_LABEL       : 'Japanese company name',
  OTHERS_PROFILE_COMPANY_NAME_OTHER_TEXT        : '株式会社エクモ',
  OTHERS_PROFILE_DIRECTOR_NAME_LABEL            : 'President and CEO',
  OTHERS_PROFILE_DIRECTOR_NAME_TEXT             : 'Tanaka Shachou',
  OTHERS_PROFILE_ADDRESS_LABEL                  : 'Office',
  OTHERS_PROFILE_POST_MARK                      : '520-2279',
  OTHERS_PROFILE_ADDRESS_TEXT                   : '滋賀県大津市黒津三丁目8-21',
  OTHERS_PROFILE_CAPITAL_LABEL                  : 'Capitalization',
  OTHERS_PROFILE_CAPITAL_TEXT                   : '100 million yen',
  OTHERS_PROFILE_STOCKHOLDER_LABEL              : 'Stockholder',
  OTHERS_PROFILE_STOCKHOLDER_TEXT               : 'KOBELCO CONSTRUCTION MACHINERY CO., LTD. 100%',
  OTHERS_PROFILE_FOUNDED_LABEL                  : 'Founded',
  OTHERS_PROFILE_FOUNDED_TEXT                   : 'September 30, 2003',
  OTHERS_PROFILE_BUSINESS_LABEL                 : 'Business Operations',
  OTHERS_PROFILE_BUSINESS_TEXT                  : 'Purchase and sale of used construction equipment',

  OTHERS_PRIVACY_TEXT                           : 'Protection of Personal Information',
  OTHERS_PRIVACY_MAIN_1_TEXT                    : 'Policy on the Protection of Personal Information',

  GUIDE_PAGE_TITLE_1                            : 'Web Auctions',

  FAQ_TITLE_TEXT                                : 'Q&A',

  SYSTEM_ERROR_MESSAGE : 'A system error has occurred.'
}


Number.prototype.numberString = function() {
  return parseInt(this, 10).toFixed(0)
    .replace(/(\d)(?=(\d{3})+$)/g, '$1,')
}

String.prototype.numberString = function() {
  if (isNaN(this)) {
    return this
  } else {
    return parseInt(this, 10).toFixed(0)
      .replace(/(\d)(?=(\d{3})+$)/g, '$1,')
  }
}

const oldConsole = console.log
console.log = function() {
  if (process.env.VUE_APP_NODE_ENV === 'stage' || process.env.VUE_APP_NODE_ENV === 'dev') {
    oldConsole.apply(this, arguments)
  }
}

/**
 * 定数定義
 */
const PAGE_NAMES = {
  LOGIN_PAGE_NAME                   : 'login',
  TOP_PAGE_NAME                     : 'index',
  DETAIL_PAGE_NAME                  : 'detail',
  CONTACT_PAGE_NAME                 : 'contact',
  ITEM_CONTACT_PAGE_NAME            : 'item_contact',
  STOCK_CONTACT_PAGE_NAME           : 'stock_contact',
  NEW_MEMBER_REGISTRATION_PAGE_NAME : 'entryinfo',
  MY_PAGE_PAGE_NAME                 : 'mypage',
  BID_HISTORY_PAGE_NAME             : 'history',
  CHANGE_INFO_PAGE_NAME             : 'change_info',
  REISSUE_PASSWORD_PAGE_NAME        : 'reissue_password',
  AUCTION_LIST_PAGE_NAME            : 'auction',
  AUCTION_DETAIL_PAGE_NAME          : 'auction_detail',
  STOCK_LIST_PAGE_NAME              : 'stock',
  STOCK_DETAIL_PAGE_NAME            : 'stock_detail',
  TENDER_LIST_PAGE_NAME             : 'tender',
  TENDER_DETAIL_PAGE_NAME           : 'tender_detail',
  GUILD_PAGE_NAME                   : 'guild',
  INTRO_PAGE_NAME                   : 'intro',
  OTHERS_PAGE_NAME                  : 'others',
  PRIVACY_PAGE_NAME                 : 'privacy',
  PROFILE_PAGE_NAME                 : 'profile',
  LOW_COMMERCE_PAGE_NAME            : 'tradelaw',
  FAQ_PAGE_NAME                     : 'faq',
  WITHDRAW_SUCCESS_PAGE_NAME        : 'withdraw_success',
  PRINTER                           : 'printer',
  SYSTEM_ERROR_PAGE_NAME            : 'system_error',
}

// Page title
const DEFAULT_PAGE_TITLE = 'サタデーオークション'
const PAGE_TITLES = {
  LOGIN_PAGE_NAME                   : `${'ログイン｜'}${DEFAULT_PAGE_TITLE}`,
  TOP_PAGE_NAME                     : `${DEFAULT_PAGE_TITLE}｜競走馬オークション`,
  DETAIL_PAGE_NAME                  : DEFAULT_PAGE_TITLE,
  CONTACT_PAGE_NAME                 : DEFAULT_PAGE_TITLE,
  ITEM_CONTACT_PAGE_NAME            : DEFAULT_PAGE_TITLE,
  STOCK_CONTACT_PAGE_NAME           : DEFAULT_PAGE_TITLE,
  NEW_MEMBER_REGISTRATION_PAGE_NAME : `${'会員登録｜'}${DEFAULT_PAGE_TITLE}`,
  MY_PAGE_PAGE_NAME                 : `${'マイページ｜'}${DEFAULT_PAGE_TITLE}`,
  BID_HISTORY_PAGE_NAME             : `${'過去の落札馬｜'}${DEFAULT_PAGE_TITLE}`,
  CHANGE_INFO_PAGE_NAME             : `${'マイページ｜'}${DEFAULT_PAGE_TITLE}`,
  REISSUE_PASSWORD_PAGE_NAME        : `${'パスワードをお忘れの方｜'}${DEFAULT_PAGE_TITLE}`,
  AUCTION_LIST_PAGE_NAME            : DEFAULT_PAGE_TITLE,
  AUCTION_DETAIL_PAGE_NAME          : DEFAULT_PAGE_TITLE,
  STOCK_LIST_PAGE_NAME              : DEFAULT_PAGE_TITLE,
  STOCK_DETAIL_PAGE_NAME            : DEFAULT_PAGE_TITLE,
  TENDER_LIST_PAGE_NAME             : DEFAULT_PAGE_TITLE,
  TENDER_DETAIL_PAGE_NAME           : DEFAULT_PAGE_TITLE,
  GUILD_PAGE_NAME                   : `${'ガイド｜'}${DEFAULT_PAGE_TITLE}`,
  INTRO_PAGE_NAME                   : `${'参加される方へ｜'}${DEFAULT_PAGE_TITLE}`,
  PRIVACY_PAGE_NAME                 : `${'個人情報保護方針｜'}${DEFAULT_PAGE_TITLE}`,
  PROFILE_PAGE_NAME                 : `${'会社概要｜'}${DEFAULT_PAGE_TITLE}`,
  LOW_COMMERCE_PAGE_NAME            : `${'特定商取引法に基づく表記｜'}${DEFAULT_PAGE_TITLE}`,
  FAQ_PAGE_NAME                     : `${'Q&A｜'}${DEFAULT_PAGE_TITLE}`,
  WITHDRAW_SUCCESS_PAGE_NAME        : `${'退会完了｜'}${DEFAULT_PAGE_TITLE}`,
  PRINTER                           : DEFAULT_PAGE_TITLE,
  SYSTEM_ERROR_PAGE_NAME            : `${'システムエラー｜'}${DEFAULT_PAGE_TITLE}`
}

const LOCAL_STORE_LABEL = {
  SESSION_TOKEN   : 'ss_id',
  LOGIN_USER_NAME : 'user_name',
}

// ページング
const LIMIT_PER_PAGE = [100, 200]

module.exports = {
  PAGE_NAMES,
  PAGE_TITLES,
  LOCAL_STORE_LABEL,
  LIMIT_PER_PAGE
}

<template>
<header class="header">
  <div class="header_pc">
    <div class="header_top">
      <div class="content_wrap u-p-relative">
        <h1 class="header_title"><a @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME)">{{$t('SITE_TITLE')}}</a></h1>
        <p class="header_login">
          <template v-if="logined && $route.name != $define.PAGE_NAMES.LOGIN_PAGE_NAME">
            <a class="header_nickname">{{nickname + $t("DETAIL_BID_CURRENT_CANDIDATE_SAN_LABEL")}}</a>
            <a class="header_login_link" @click="logout">{{$t("HEADER_LOGOUT_BUTTON_LABEL")}}</a>
          </template>
          <template v-else>
            <a class="header_login_link" @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t("PAGE_LOGIN")}}</a>
          </template>
        </p>
      </div>
    </div>
    <div class="header_list">
      <div class="content_wrap">
        <ul class="row u-mr0 u-ml0">
          <li class="header_list_link"
           v-bind:class="{'active': $route.name === $define.PAGE_NAMES.TOP_PAGE_NAME}">
            <a @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME)">{{$t('PAGE_TOP_NAME')}}</a>
          </li>
          <li class="header_list_link"
           v-bind:class="{'active': $route.name === $define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME}">
            <a @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">{{$t('PAGE_MEMBER_REGISTRATION')}}</a>
          </li>
          <li class="header_list_link"
           v-bind:class="{'active': $route.name === $define.PAGE_NAMES.INTRO_PAGE_NAME}">
            <a @click="$routerGo($define.PAGE_NAMES.INTRO_PAGE_NAME)">{{$t('PAGE_INTRO_NAME')}}</a>
          </li>
          <li class="header_list_link"
           v-bind:class="{'active': $route.name === $define.PAGE_NAMES.FAQ_PAGE_NAME}">
           <a @click="$routerGo($define.PAGE_NAMES.FAQ_PAGE_NAME)">{{$t('LINK_GUIDANCE_FAQ')}}</a>
          </li>
          <li class="header_list_link" v-bind:class="{'active': ($route.name === $define.PAGE_NAMES.MY_PAGE_PAGE_NAME) || ($route.name === $define.PAGE_NAMES.BID_HISTORY_PAGE_NAME)}">
            <a @click="$routerGo($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)">{{$t('PAGE_MY_PAGE')}}</a>
            <ul class="header_list_sub">
              <li class="header_child"><a @click="$routerGo($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)">{{$t('PAGE_MY_PAGE_MEMBER_INFO')}}</a></li>
              <li class="header_child"><a @click="$routerGo($define.PAGE_NAMES.BID_HISTORY_PAGE_NAME)">{{$t('PAGE_MY_PAGE_HISTORY')}}</a></li>
            </ul>
          </li>
          <li class="header_list_link" v-bind:class="{'active': ($route.name === $define.PAGE_NAMES.PROFILE_PAGE_NAME) || ($route.name === $define.PAGE_NAMES.PRIVACY_PAGE_NAME) || ($route.name === $define.PAGE_NAMES.LOW_COMMERCE_PAGE_NAME)}">
            <a @click="$routerGo($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{$t('LINK_PROFILE_GUILD')}}</a>
            <ul class="header_list_sub">
              <li class="header_child"><a @click="$routerGo($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{$t('LINK_PROFILE')}}</a></li>
              <li class="header_child"><a @click="$routerGo($define.PAGE_NAMES.PRIVACY_PAGE_NAME)">{{$t('LINK_PRIVACY')}}</a></li>
              <li class="header_child"><a @click="$routerGo($define.PAGE_NAMES.LOW_COMMERCE_PAGE_NAME)" v-html="$t('LINK_LOW_COMMERCE')"/></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="header_sp">
    <div class="header_title">
      <h1>{{$t('SITE_TITLE')}}</h1>
    </div>
    <div class="hamburger-menu">
      <input type="checkbox" class="header-btn" id="header-btn">
      <label for="header-btn" class="header-icon"><span class="navicon"></span></label>
      <!--ここからメニュー-->
      <div class="menu-content">
          <ul>
            <li class="sp_nickname" v-if="logined && $route.name != $define.PAGE_NAMES.LOGIN_PAGE_NAME">
              <p>{{nickname + $t("DETAIL_BID_CURRENT_CANDIDATE_SAN_LABEL")}}</p>
            </li>
            <li class=""><a @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME)">{{$t('PAGE_TOP_NAME')}}</a></li>
            <li class="">
              <a v-if="logined && $route.name != $define.PAGE_NAMES.LOGIN_PAGE_NAME"
                @click="logout">{{$t("HEADER_LOGOUT_BUTTON_LABEL")}}</a>
              <a v-else @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t('PAGE_LOGIN')}}</a>
            </li>
            <li class=""><a @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">{{$t('PAGE_MEMBER_REGISTRATION')}}</a></li>
            <li class=""><a @click="$routerGo($define.PAGE_NAMES.INTRO_PAGE_NAME)">{{$t('PAGE_INTRO_NAME')}}</a></li>
            <li class=""><a @click="$routerGo($define.PAGE_NAMES.FAQ_PAGE_NAME)">{{$t('LINK_GUIDANCE_FAQ')}}</a></li>
            <!-- <li class=""><a @click="$routerGo($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)">{{$t('PAGE_MY_PAGE')}}</a></li> -->
            <li class=""><a @click="$routerGo($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)">{{$t('PAGE_MY_PAGE_MEMBER_INFO')}}</a></li>
            <li class=""><a @click="$routerGo($define.PAGE_NAMES.BID_HISTORY_PAGE_NAME)">{{$t('PAGE_MY_PAGE_HISTORY')}}</a></li>
            <li class=""><a @click="$routerGo($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{$t('LINK_PROFILE')}}</a></li>
            <li class=""><a @click="$routerGo($define.PAGE_NAMES.PRIVACY_PAGE_NAME)">{{$t('LINK_PRIVACY')}}</a></li>
            <li class=""><a @click="$routerGo($define.PAGE_NAMES.LOW_COMMERCE_PAGE_NAME)" v-html="$t('LINK_LOW_COMMERCE')"></a></li>
          </ul>
      </div>
      <!--ここまでメニュー-->
    </div>


  </div>
</header>
</template>
<script>
export default {
  props : {
    logined : {
      type    : Boolean,
      default : false
    }
  },
  data() {
    return {
      nickname : ''
    }
  },
  mounted() {
    this.getUserNickname()
  },
  methods : {
    logout() {
      this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)
      this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.LOGIN_USER_NAME)
      this.$routerGo(this.$define.PAGE_NAMES.LOGIN_PAGE_NAME)
    },
    getUserNickname() {
      const loginUserName = this.$cookies.get(this.$define.LOCAL_STORE_LABEL.LOGIN_USER_NAME)
      this.nickname = loginUserName || ''
    },
    parseJwt(token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(window.atob(base64).split('')
        .map(c => {
          return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`
        })
        .join(''))
      return JSON.parse(jsonPayload)
    }
  },
  watch : {
    '$store.state.nickNameChangedCount'() {
      this.getUserNickname()
    }
  }
}
</script>
<style scoped>
  .header_login_link {
    color: #FFF !important;
  }
  a {
    color: #333 !important;
  }
  .menu-content >>> a {
    color: #FFF !important;
  }
  .v-application p {
    margin-bottom: inherit !important;
  }
  .v-application ul, .v-application ol {
    padding-left: inherit !important;
  }
  @media screen and (max-width: 1024px) {
    .menu-content ul {
      padding-left: 10px !important;
    }
    .menu-content ul li a::before {
      width: 9px;
      height: 9px;
    }
  }
  @media screen and (min-width: 769px) {
    .header_list .content_wrap .row {
      margin: 0 !important
    }
  }
  .header_title a {
    color: #fff !important;
    cursor: pointer !important;
    text-decoration: none !important;
  }
  .header_login {
    display: flex;
    float: right;
  }
  .header_nickname {
    margin: auto 25px;
    color: #fff600 !important;
    text-decoration: none;
    cursor: inherit;
  }
  .header_login_link {
    position: inherit;
  }
  .sp_nickname {
    text-align: center;
    border-bottom: none;
  }
  .sp_nickname p {
    color: #fff600;
    font-size: 1.6rem;
  }
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import define from './api/define'
Vue.use(Vuex)

export default new Vuex.Store({
  state : {
    nickNameChangedCount : 0,
    loading              : false,
    define
  },
  mutations : {
    nickNameChanged(state) {
      state.nickNameChangedCount++
    }
  },
  actions   : {}
})
